'use strict';

module.exports = {
		
    stickyBarJumpLinks: function () {
        $('a.jumplink').on('click', function(e) {
            e.preventDefault();
            //scrolling indicator may or may not be useful
            $('.addtocart-sticky-bar').addClass('scrolling');
		   
            $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 500, function() {
                $('.addtocart-sticky-bar').removeClass('scrolling');  
            });
        });
    },
		
    addToCartStickyBar: function (){
       
        //jumplinks - ** Currently deactivated ** : add all jumplinks and related anchor content that exists in an array that contains jumplink object and content area
        /*
        var allJumplinks = $('.addtocart-sticky-bar .jumplink');
        var jumplinkArray = [];
        allJumplinks.each(function(index) {
            var $jumplink = $(allJumplinks[index]);
            var jumplinkContentElement = $($jumplink.attr('href')).parent();
            
            if (jumplinkContentElement && jumplinkContentElement.length > 0) {
                var jumplinkObject = {
                    jumplink: $jumplink,
                    jumplinkElementTop: jumplinkContentElement.offset().top,
                    jumplinkElementBottom: jumplinkContentElement.outerHeight() + jumplinkContentElement.offset().top 
            }
                jumplinkArray.push(jumplinkObject);
            }
        });
        */
		
        //main add to cart sticky vars
        var SiteConstants = require('constants/SiteConstants');
        var addToCartStickyBar = document.getElementById('addtocart-sticky-bar');
        var addToCartContainer = document.getElementById('qty-cart-container');
        var $body = $('body');
        var topHeader = document.getElementById('top-header');
		
        //if header is fixed, use just the nav bar to measure top of page, else use the entire header (may contain promo bar)
        if (topHeader.classList.contains('fixed-header')) {
            var topHeaderHeight = document.getElementById('header-nav').offsetHeight;
        } else {
            var topHeaderHeight = 0;
        }
	
        if (addToCartStickyBar && addToCartContainer) {
            window.addEventListener("scrollUpdate", function() {
                //main sticky add to cart functionality - only show when scrolled past in-page add to cart section	
                if (window.pageYOffset > (addToCartContainer.offsetTop + addToCartContainer.offsetHeight + topHeaderHeight)) {
                    $body.addClass('showstickybar');	
                    addToCartStickyBar.setAttribute("style", 'top:' + topHeaderHeight + 'px;');
                } else if ($body.hasClass('showstickybar')){
                    $body.removeClass('showstickybar');
                    setTimeout(() => {
                        addToCartStickyBar.removeAttribute("style");
                    }, SiteConstants.TransitionSpeed);
                }
            
                // ** Jumplinks are Currently Deactivated **
                /*
                var bottomOfTopWindow = window.scrollY + topHeaderHeight + addToCartStickyBar.offsetHeight;
                jumplinkArray.forEach(function(jumplinkObject) {
                    if (bottomOfTopWindow > jumplinkObject.jumplinkElementTop && bottomOfTopWindow < jumplinkObject.jumplinkElementBottom ) {
                        jumplinkObject.jumplink.addClass('selected');
                    } else {
                        jumplinkObject.jumplink.removeClass('selected');
                    }
                });
                */
            });
        }
    }
}