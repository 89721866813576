'use strict';

var recaptcha = require('core/components/recaptcha');

/**
 * Get the cart quantity.
 * @param {string} url - URL of the controller to get the cart model
 * @param {number=} delay - milliseconds to wait before getting the cart (default is 1000)
 * @returns {Promise} - promise to resolve the cart model and its quantity
 */
function getCartQuantity(url, delay) {
    return new Promise(function (resolve, reject) {
        // Default delay to 1 second
        var timeoutMillis = delay || 1000;

        setTimeout(function () {
            $.ajax({
                url: url,
                contentType: 'application/json; charset=UTF-8',
                success: function (response) {
                    if (response.quantityTotal) {
                        // Resolve response for mini cart update
                        resolve(response);
                    } else {
                        // Get the cart again after a longer delay
                        getCartQuantity(url, timeoutMillis * 2).then(resolve, reject);
                    }
                },
                error: reject
            });
        }, timeoutMillis);
    });
}

module.exports = {
    updateBuyNowRequest: function () {
        if (window.sfpp) {
            sfpp.ready(function () {   // eslint-disable-line
                var errorElement = $(document.querySelector('.salesforce-buynow-element-errors'));
                var buynow = sfpp.get('buynow');   // eslint-disable-line

                var getCartUrl = $('.salesforce-buynow-element').data('getcart');
                var placeOrderUrl = $('.salesforce-buynow-element').data('placeorder');

                var paypalApproveUrl = $('.salesforce-buynow-element').data('paypalapprove');

                var paypalMessage = $('.prices-add-to-cart-actions .salesforce-paypal-messaging');

                $('body').on('product:updateAddToCart', function (e, response) {
                    // Update payment request data and options
                    buynow.updateBasketData(response.product.buynow.basketData);
                    buynow.updatePaymentRequest(response.product.buynow.options);

                    // Update amount in PayPal messages component
                    paypalMessage.find('div[data-pp-message]').attr('data-pp-amount', response.product.buynow.paypalAmount);
                });

                buynow.on('cancel', function () {
                    window.location.reload();
                });

                // Show PayPal messages component if PayPal button rendered
                buynow.on('render', function () {
                    if (buynow.getRenderedTypes().indexOf('paypalexpress') !== -1) {
                        paypalMessage.removeClass('salesforce-paypal-messaging-hidden');
                    }
                });

                // Update mini cart count once basket is prepared
                buynow.on('click', function () {
                    getCartQuantity(getCartUrl).then(function (response) {
                        $('.minicart').trigger('count:update', response);
                        $('body').trigger('product:afterAddToCart', response);
                    });
                });

                // Update error message on change
                buynow.on('change', function (event) {
                    if (event.error) {
                        // Inform the customer that there is an error.
                        errorElement.empty().text(event.error.message);
                    } else {
                        // Clear out error message
                        errorElement.empty();
                    }
                });

                buynow.on('payment', function (event) {
                    recaptcha.check(event, {
                        url: placeOrderUrl,
                        method: 'POST',
                        success: function (data) {
                            if (data.error) {
                                // Inform the customer that there is an error.
                                errorElement.empty().text(data.errorMessage);
                            } else {
                                var redirect = $('<form>')
                                    .appendTo(document.body)
                                    .attr({
                                        method: 'POST',
                                        action: data.continueUrl
                                    });

                                $('<input>')
                                    .appendTo(redirect)
                                    .attr({
                                        name: 'orderID',
                                        value: data.orderID
                                    });

                                $('<input>')
                                    .appendTo(redirect)
                                    .attr({
                                        name: 'orderToken',
                                        value: data.orderToken
                                    });

                                redirect.submit();
                            }
                        },
                        error: function (err) {
                            if (err.message) {
                                // Inform the customer that there is an error.
                                errorElement.empty().text(err.message);
                            }
                        }
                    });
                });

                buynow.on('paypal.approve', function () {
                    $.ajax({
                        url: paypalApproveUrl,
                        type: 'post',
                        data: '',
                        success: function (data) {
                            window.location.href = data.redirectUrl;
                        },
                        error: function (err) {
                            if (err.responseJSON && err.responseJSON.redirectUrl) {
                                window.location.href = err.responseJSON.redirectUrl;
                            } else if (err.message) {
                                // Inform the customer that there is an error.
                                errorElement.empty().text(err.message);
                            }
                         }
                     });
                 });
            });
        }
    }
};
